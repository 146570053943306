import { Accordion, ActionIcon, Box, Button, Checkbox, CloseButton, Divider, Grid, Group, Image, MultiSelect, NumberInput, Select, Stack, Switch, Tabs, TagsInput, Text, TextInput, Textarea, rem, useMantineTheme } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import FormData from "form-data";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconCheck, IconCloudCheck, IconMinus, IconPhoto, IconPlus, IconShoppingCartFilled, IconTrash, IconUpload, IconX, IconXboxX } from "@tabler/icons-react";
import classes from "../../styles/AddProduct.module.css"

import Wilayas from '../../helper/wilayas.json';
import Communes from '../../helper/communes.json';
import axios from "axios";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;

export const AddDiscountOrder = (props: Props) => {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values, errors} = useForm({
        initialValues: { discount: 0 }
    });

    useEffect(() => {
        if (props.opened && props.data !== null) {
            const order = props.data
            setValues({ discount: order?.discount || 0 })
        }
    }, [props.opened, props?.data])


    const onSubmitForm = ({discount}: any) => {
        setLoading(true)
        
        client.put(`/orders/${props.data._id}`, {
            "discount": discount
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم اضافة الخصم", color: '#fff' });
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: "فشل اضافة الخصم", color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }
    
    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}} >
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="flex-start">
                        <Grid.Col span={12} >
                            <NumberInput
                                label={"الخصم"}
                                placeholder={"الخصم"}
                                withAsterisk
                                min={0}
                                {...getInputProps('discount')}
                            />
                        </Grid.Col>
                        <Grid.Col span={{base: 12}} >
                            <Text size="sm" fw={"bold"} mb={5}>
                                اسم العميل:
                                <Text ms={5} span={true}>{props.data?.fullName}</Text>
                            </Text>
                            <Text size="sm" fw={"bold"} mb={5}>
                                السعر الاصلي:
                                <Text ms={5} span={true}>{props.data?.price * props.data?.quantity} دج</Text>
                            </Text>
                            <Text size="sm" fw={"bold"} mb={5}>
                                الخصم:
                                <Text ms={5} span={true}>{values?.discount * props.data?.quantity} دج</Text>
                            </Text>
                            <Text size="sm" fw={"bold"}>
                                السعر بعد الخصم:
                                <Text ms={5} span={true}>{(props.data?.price  - values?.discount) * props.data?.quantity} دج</Text>
                            </Text>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};
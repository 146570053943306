import { ActionIcon, Box, Button, Divider, Grid, Group, Image, NumberInput, Select, Stack, Text, TextInput, rem } from "@mantine/core";
import Modal, { Props as ModalProps } from "./Modal";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { client } from "../../lib/axiosClient";
import FormData from "form-data";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconCheck, IconCloudUpload, IconPhoto, IconPlus, IconTrash, IconUpload, IconX } from "@tabler/icons-react";
import { zodResolver } from 'mantine-form-zod-resolver';
import { z } from 'zod';
import { notifications } from "@mantine/notifications";

import Cookies from "universal-cookie";
import { useTranslation } from "react-i18next";
import { useUsers } from "../../api";

const cookies = new Cookies(null, { path: '/' });
type Props = {
    setSelectedData?: (id: string) => void;
    data?: any;
    refetchData?: () => void;
} & ModalProps;


export const UpdatePriceMarketer = ({data, ...props}: Props) => {
    const { t } = useTranslation();
    
    const [loading, setLoading] = useState(false);
    const {onSubmit, reset, getInputProps, setValues, values} = useForm({
        initialValues: {
            priceMarketers: []
        }
    });
    const [price, setPrice] = useState<string | number>(0);
    const [users, setUsers] = useState<{label: string, value: string}[]>([]);
    const {data: dataUser, refetch} = useUsers()

    useEffect(() => {
        if (props.opened && props.opened === true) {
            refetch()
        }
    }, [props.opened])
    
    useEffect(() => {
        console.log(dataUser);
        if (props.opened && dataUser.length > 0) {
            let newData: {label: string, value: string}[] = []
            for (let i = 0; i < dataUser.length; i++) {
                const element = dataUser[i];
                if (element.role == "marketer") {
                    newData.push({label: element.name, value: element._id})
                }
            }
            setUsers(newData)
        }
    }, [dataUser])

    useEffect(() => {
        console.log(users);
        if (props.opened && data !== null && users.length > 0) {
            const priceMarketers = data.priceMarketer
            
            console.log(users);
            let newPriceMarketers = []
            for (let i = 0; i < users.length; i++) {
                const user = users[i];
                const index = priceMarketers?.findIndex((item: any) => item.idMarketer == user.value)
                if (index >= 0) {
                    newPriceMarketers.push({idMarketer: user.value, price: priceMarketers[index].price})
                } else {
                    newPriceMarketers.push({idMarketer: user.value, price: 0})
                }
            }

            console.log(newPriceMarketers);
            
            
            setValues({
                //@ts-ignore
                priceMarketers: newPriceMarketers
            })
        }
    }, [props.opened, users, data])

    useEffect(() => {
        if (price) {
            let newPriceMarketers = []
            for (let i = 0; i < values.priceMarketers.length; i++) {
                const item: {idMarketer: string, price: number} = values.priceMarketers[i];
                newPriceMarketers.push({idMarketer: item.idMarketer, price: price})
            }
            
            setValues({
                //@ts-ignore
                priceMarketers: newPriceMarketers
            })
        }
    }, [price])

    const onSubmitForm = ({priceMarketers}: any) => {
        setLoading(true)

        //@ts-ignore
        client.put(`/addPriceMarketersProducts/${data?._id}`, priceMarketers, {
            headers: {
                'Accept': 'application/json',
                'Authorization': cookies.get('token') || ""
            }
        })
        .then(({data}) => {
            notifications.show({ message: "تم تعديل عمولة المسوقين", color: '#fff' });
            console.log(data);
            setLoading(false)
            typeof props?.refetchData == "function" && props?.refetchData()
            closeModal()
        })
        .catch((error) => {
            notifications.show({ message: "فشل تعديل عمولة المسوقين", color: '#fff' });
            console.log(error)
            setLoading(false)
        });
    }

    const closeModal = () => {
        reset();
        props.onClose();
        setLoading(false)
        setPrice(0)
    };

    return (
        <Modal
            {...props} onClose={closeModal} loading={loading}
            footer={
                <Box py={16} px={20} bg="slate.0">
                    <Group justify={"flex-end"} gap={"xs"}>
                        <Button color={'gray'} variant="outline" rightSection={<IconX size={15} />} bg="white" onClick={closeModal}>{t('modals.cancelling')}</Button>
                        <Button color={'#323232'} rightSection={<IconCheck size={15} />} type="submit" form="submit_form">{t('modals.confirmation')}</Button>
                    </Group>
                </Box>
            }
        >
            <Box style={{padding: 20}} >
                <form onSubmit={onSubmit(onSubmitForm)} id="submit_form">
                    <Grid gutter={20} justify="center">
                        <Grid.Col span={12} >
                            <NumberInput
                                label={"العمولة للجميع"}
                                placeholder={"العمولة للجميع"}
                                value={price} onChange={setPrice}
                            />
                        </Grid.Col>

                        {values.priceMarketers.map((item, index) => (
                            <Grid.Col span={12} key={index}>
                                <Group w={"100%"} wrap="nowrap">
                                    <Select
                                        label={"المسوق"}
                                        placeholder={"المسوق"}
                                        data={users}
                                        withAsterisk
                                        readOnly
                                        rightSection={<></>}
                                        {...getInputProps(`priceMarketers.${index}.idMarketer`)}
                                        w={"100%"}
                                    />
                                    <NumberInput
                                        label={"العمولة"}
                                        placeholder={"العمولة"}
                                        withAsterisk
                                        {...getInputProps(`priceMarketers.${index}.price`)}
                                        w={"100%"}
                                    />
                                </Group>
                            </Grid.Col>
                        )) }
                    </Grid>
                </form>
            </Box>
        </Modal>
    );
};